<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                {{ $t("message.product") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <crm-input
                    :size="'small'"
                    :className="'w100'"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    :icon="'el-icon-search'"
                  ></crm-input>
                  
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            <el-button-group class="mr-2">
					<el-button @click="exampleForImport" size="small" class="btu-color" icon="el-icon-info"></el-button>
					<el-button size="small" class="btu-color" @click="importProducts()">
						{{ $t('message.import_products') }}
						<i class="el-icon-upload"></i>
					</el-button>
				</el-button-group>
				
				<el-button class="btu-color" @click="downloadExcelFile()" icon="el-icon-document-delete" size="small">
					{{ $t("message.excel") }}
				</el-button>

            <el-button size="small" class="btu-color" @click="drawerCreate = true">
                {{ $t('message.create') }}
            </el-button>
            <el-dropdown class="setting-cheek ml-2">
                <el-button
                    class="padding_none"
                    size="small"
                    icon="el-icon-open"
                ></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(column, index) in columns"
                        :key="'drop' + index"
                    >
                        <el-checkbox
                            :checked="column.show"
                            @change="updateColumn({key: column.column, value: $event})"
                            >{{ column.title }}
                        </el-checkbox>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.code.show">
                {{ columns.code.title }}
              </th>

				   <th v-if="columns.vendor_code.show">
                {{ columns.vendor_code.title }}
              </th>

              <th v-if="columns.buy_price.show">
                {{ columns.buy_price.title }}
              </th>

              <th v-if="columns.buy_price_currency_id.show">
                {{ columns.buy_price_currency_id.title }}
              </th>

              <th v-if="columns.selling_price.show">
                {{ columns.selling_price.title }}
              </th>

              <th v-if="columns.selling_price_currency_id.show">
                {{ columns.selling_price_currency_id.title }}
              </th>

              <th v-if="columns.measurement_id.show">
                {{ columns.measurement_id.title }}
              </th>
              
              <th v-if="columns.product_model_id.show">
                {{ columns.product_model_id.title }}
              </th>

              <th v-if="columns.categories.show">
                {{ columns.categories.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.production.show">
                {{ columns.production.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>

              <th v-if="columns.name.show">
                <crm-input
                  size="mini"
                  :placeholder="columns.name.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.name"
                ></crm-input>
              </th>

              <th v-if="columns.code.show">
                <crm-input
                  size="mini"
                  :placeholder="columns.code.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.code"
                ></crm-input>
              </th>

				   <th v-if="columns.vendor_code.show">
                <crm-input
                  size="mini"
                  :placeholder="columns.vendor_code.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.vendor_code"
                ></crm-input>
              </th>

              <th v-if="columns.buy_price.show">
                <crm-input
                  :placeholder="columns.buy_price.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  size="mini"
                  v-model="filterForm.buy_price"
                ></crm-input>
              </th>

              <th v-if="columns.buy_price_currency_id.show">
                <select-currency
                    :size="'mini'"
                    :placeholder="columns.buy_price_currency_id.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :id="filterForm.buy_price_currency_id"
                    v-model="filterForm.buy_price_currency_id"
                ></select-currency>
              </th>

              <th v-if="columns.selling_price.show">
                <crm-input
                  size="mini"
                  :placeholder="columns.selling_price.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.selling_price"
                ></crm-input>
              </th>

              <th v-if="columns.selling_price_currency_id.show">
                <select-currency
                    :size="'mini'"
                    :placeholder="columns.selling_price_currency_id.title"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :id="filterForm.selling_price_currency_id"
                    v-model="filterForm.selling_price_currency_id"
                ></select-currency>
              </th>

              <th v-if="columns.measurement_id.show">
                <select-measurement
                  :size="'mini'"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.measurement_id.title"
                  :id="filterForm.measurement_id"
                  v-model="filterForm.measurement_id"
                >
                </select-measurement>
              </th>

              <th v-if="columns.product_model_id.show">
                <select-model
                  :size="'mini'"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.product_model_id.title"
                  :id="filterForm.product_model_id"
                  v-model="filterForm.product_model_id"
                >
                </select-model>
              </th>

              <th v-if="columns.categories.show">
                <crm-input
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.categories.title"
                  v-model="filterForm.categories"
                ></crm-input>
              </th>

              <th v-if="columns.production.show">
                <el-select v-model="filterForm.production" :placeholder="columns.production.title" size="mini" clearable>
                  <el-option :label="$t('message.ready')" :value="0"> </el-option>
                  <el-option :label="$t('message.productionable')" :value="1"> </el-option>
                </el-select>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.created_at.title"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.updated_at.title"
                  v-model="filterForm.updated_at"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="product in list" :key="product.id" class="cursor-pointer">
              <td v-if="columns.id.show">
                {{ product.id }}
              </td>

              <td v-if="columns.name.show">
                <span @click="openCompositionDrawer(product)">
                   <i v-if="product.has_composition" class="el-icon-notebook-2 mm_notebook_icon"></i> {{ product.name }} 
                </span>
              </td>

              <td v-if="columns.code.show">
                {{ product.code }}
              </td>

				   <td v-if="columns.vendor_code.show">
                {{ product.vendor_code }}
              </td>

              <td v-if="columns.buy_price.show">
                {{ product.buy_price }}
              </td>

              <td v-if="columns.buy_price_currency_id.show">
                {{ product.buy_price_currency ? product.buy_price_currency.code : ''}}
              </td>

              <td v-if="columns.selling_price.show">
                {{ product.selling_price }}
              </td>

              <td v-if="columns.selling_price_currency_id.show">
                {{ product.selling_price_currency ? product.selling_price_currency.code : '' }}
              </td>

              <td v-if="columns.measurement_id.show">
                {{ product.measurement ? product.measurement.name : '' }}
              </td>

              <td v-if="columns.product_model_id.show">
                {{ product.model ? product.model.name : '' }}
              </td>

              <td v-if="columns.categories.show">
                  <div v-for="category in product.categories" :key="category.id">
                    {{ category ? category.name : '' }},
                  </div>
              </td>

              <td v-if="columns.production.show">
                {{ (product.production && product.production == 1) ? $t('message.productionable') : $t('message.ready') }}
              </td>

              <td v-if="columns.created_at.show">
                {{ product.created_at }}
              </td>

              <td v-if="columns.updated_at.show">
                {{ product.updated_at }}
              </td>

              <td v-if="columns.settings.show" class="settings-td">
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link more_icons">
                      <i class="el-icon-arrow-down el-icon-more-outline"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown" size="mini">      
                        <el-dropdown-item :command="{action: 'update', product: product}" icon="el-icon-edit el-icon--left" v-if="permissions.some(per => per.slug == 'products.update') ? true : false">       
                            {{ $t('message.update') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{action: 'printBarcode', product: product}" icon="el-icon-printer el-icon--left" v-if="permissions.some(per => per.slug == 'products.printBarCode') && product.code ? true : false">       
                            {{ $t('message.print') }}
                        </el-dropdown-item>
                        <el-dropdown-item :command="{action: 'delete', product: product}" icon="el-icon-delete el-icon--left" v-if="permissions.some(per => per.slug == 'products.delete') ? true : false">
                            {{ $t('message.delete') }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>

      <div class="app-modal app-modal__full modal-color-bg">
        <el-drawer
          :class="mode ? 'l-modal-style' : 'd-modal-style'"
          :with-header="false"
          :visible.sync="drawerCreate"
          ref="drawerCreate"
          class="body_scroll_70"
          size="70%"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <div>
            <crm-create ref="drawerCreateChild" drawer="drawerCreate">
            </crm-create>
          </div>
        </el-drawer>

        <el-drawer
          :class="mode ? 'l-modal-style' : 'd-modal-style'"
          :with-header="false"
          :visible.sync="drawerUpdate"
          class="body_scroll_70"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>

        <el-drawer
          :class="mode ? 'l-modal-style' : 'd-modal-style'"
          :append-to-body="true"
          size="70%"
          :with-header="false"
          :visible.sync="drawerProductComposition"
          custom-class="modal-style-new-v"
          ref="drawerProductComposition"
        >
            <product-composition
              @updateProductComposition="updateProductComposition"
              :productComposition="selectedItem ? selectedItem.composition : {}"
              ref="drawerProductCompositionChild"
              drawer="drawerProductComposition"
            ></product-composition>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import selectCurrency from "@/components/selects/select-currency";
import selectMeasurement from "@/components/inventory/select-measurement";
import selectModel from "@/components/inventory/select-product-model";
import productComposition from "@/views/deals/components/dealProduct/product-composition";

import { mapGetters, mapActions } from "vuex";

export default {
	name: "products",
	mixins: [list],
	components: {
		Pagination,
		CrmCreate,
		CrmUpdate,
		selectCurrency,
		selectMeasurement,
		selectModel,
		productComposition,
	},
	data() {
		return {
			drawerProductComposition: false
		};
	},
	computed: {
		...mapGetters({
			list: "products/list",
			currencies: "currency/inventory",
			model: "products/model",
			columns: "products/columns",
			pagination: "products/pagination",
			filter: "products/filter",
			sort: "products/sort",
			permissions: "auth/permissions",
			mode: "MODE"
		}),
		actions: function () {
			return ["edit", "delete"];
		},
	},
   methods: {
		...mapActions({
			updateList: "products/index",
			setPagination: "products/setPagination",
			updateSort: "products/updateSort",
			updateFilter: "products/updateFilter",
			updateColumn: "products/updateColumn",
			updatePagination: "products/updatePagination",
			show: "products/show",
			empty: "products/empty",
			delete: "products/destroy",
			refreshData: "products/refreshData",
			getProducts: "products/getProducts",
			printModel: "products/printBarcode",
			setItemValues: "products/setItemValues",
			updateComposition: "products/updateComposition",
			excelFileDownload: "products/excelFileDownload"
		}),

		downloadExcelFile() {
			this.excelFileDownload()
				.then((res) => {
					let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob)
					link.setAttribute("download", 'products.xlsx');
					document.body.appendChild(link);
					link.click();
				})
				.catch((err) => {
					this.$alert(err);
				});
		},
		exampleForImport(){
			this.$confirm(
				this.$t('message.Do you really want to import products from another project'),
				this.$t('message.warning'), {
					confirmButtonText: this.$t('message.upload_file'),
					cancelButtonText: "Отмен",
					type: "warning",
					center: true
				}
			)
			.then(() => {
				this.loadingData = true;
				// this.getProducts()
				// 	.then(response => {
				// 	const query = {...this.filter, ...this.pagination, ...this.sort,};
				// 	this.updateList(query)
				// 	.then(res => {
				// 		this.loadingData = false;
				// 		this.$alert(response);
				// 	});
				// });
			})
			.catch(() => {
				this.loadingData = false
				this.$message({
					type: "warning",
					message: this.$t('message.operation_canceled')
				});
			});
		},
		importProducts(){
			this.$confirm(
				$t('message.Do you really want to import products from another project'),
				this.$t('message.warning'), {
					confirmButtonText: "Да",
					cancelButtonText: "Отмен",
					type: "warning"
				}
			)
			.then(() => {
				this.loadingData = true,
				this.getProducts()
					.then(response => {
					const query = {...this.filter, ...this.pagination, ...this.sort,};
					this.updateList(query)
					.then(res => {
						this.loadingData = false;
						this.$alert(response);
					});
					})
			})
			.catch(() => {
				this.loadingData = false
				this.$message({
					type: "warning",
					message: this.$t('message.operation_canceled')
				});
			});
		},
		handleCommand(command){
			if(command.action === 'delete'){
				this.$confirm(
						this.$t('message.do_you_really_want_to_do_this'),
						this.$t("message.warning"),
						{
						confirmButtonText: this.$t("message.yes"),
						cancelButtonText: this.$t("message.no"),
						type: "warning",
						}
				)
				.then(() => {
						this.destroy(command.product);
				})
				.catch(() => {
				})
			}else if(command.action === 'printBarcode') {
				this.print(command.product);
			}else if(command.action === 'update') {
				this.edit(command.product);
			}
			
		},
		openCompositionDrawer(product){
		this.loadingData = true;
		this.show(product.id)
			.then(res => {   
				this.selectedItem = JSON.parse(JSON.stringify(this.model));
				this.drawerProductComposition = true;
				this.loadingData = false;
			}).catch(err => {
				this.loadingData = false;
			});
		},
		updateProductComposition(composition, compositionTotal){
		this.$set(this.selectedItem, 'composition', JSON.parse(JSON.stringify(composition)));
		if((!this.selectedItem.child_products || this.selectedItem.child_products.length == 0) && composition.length > 0 && this.currencies && this.currencies.length > 0){
			let currency = this.currencies.find(el => el.id == this.selectedItem.selling_price_currency_id);
			if(currency && currency.rate && currency.rate > 0){
				let price =  parseFloat(compositionTotal || 0) / parseFloat(currency.rate);
				this.$set(this.selectedItem, 'selling_price', _.round(price, 2));
			}
		}
		if(composition && composition.length > 0 ){
			this.setItemValues({id: this.selectedItem.id, has_composition: true, selling_price: this.selectedItem.selling_price});
		}else{
			this.setItemValues({id: this.selectedItem.id, has_composition: false, selling_price: this.selectedItem.selling_price});
		}

		this.updateComposition(this.selectedItem)
			.then((res) => {
				this.$alert(res);
			})
			.catch((err) => {
				this.$alert(err);
			});
		},
		destroy(model) {          
			this.delete(model.id)
				.then(res => {
				if(res.data.result.success){
					this.$alert(res);
					this.fetchData()
				}else{
					this.$warning_message(res);
				}

				})
				.catch(err => {
				this.$alert(err);
				})
		},
   },
	beforeRouteLeave (to, from, next) {
		this.$store.commit('products/EMPTY_LIST');
		next();
	},
};
</script>

<style scoped>
.el-dropdown-menu__item > label {
    margin-bottom: 0.1rem !important;
}

.el-dropdown-menu {
    max-height: 720px !important;
    overflow-y: scroll;
}
.el-dropdown-menu__item,
.el-checkbox__label,
.el-checkbox {
    font-size: 12px !important;
}
.mm_notebook_icon{
  color: rgb(7, 136, 2);
  font-size: 15px;
}

</style>
